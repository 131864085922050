import template from './email-html-editor.html';

/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email html edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		this.editor = ko_helper.safe_observable(params.editor);
		this.element = element;
		this.createEditor();
	}

	async init()
	{

	}

	async createEditor()
	{
		FroalaEditor.DefineIconTemplate('fa', '<i class="fa [NAME]"></i>');
		FroalaEditor.DefineIcon('insertFiles', {
			template: 'fa',
			NAME: 'fa-paperclip' // Use Font Awesome icon class
		});

		const editor_element = this.element.querySelector('.froala-editor');
		let editor;
		this.editor(await new Promise((resolve) => {
			editor = new FroalaEditor(editor_element, {
				key: Grape.froala.key,
				toolbarButtons: this.getToolbarButtons(),
				quickInsertButtons: ['image', 'file'],
				pluginsEnabled: ['imageTUI', 'image', 'file', 'video', 'filesManager', 'align', 'colors', 'emoticons', 'fontFamily', 'fontSize', 'link', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'lists', 'table', 'fontAwesome', 'codeView'],
				attribution: false, // remove "Powered by" watermark
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, ()=>resolve(editor));
		}));
	}

	getToolbarButtons ()
	{
		return [
			[
				'html',
				'undo',
				'redo'
			],
			[
				'bold',
				'italic',
				'underline',
				'textColor',
				'backgroundColor',
				'inlineStyle',
				'clearFormatting'
			],
			[
				'fontFamily',
				'fontSize'
			],
			[
				'alignLeft',
				'alignCenter',
				'alignRight',
				'alignJustify'
			],
			[
				'formatOL',
				'formatUL',
				'indent',
				'outdent'
			],
			[
				'paragraphFormat',
				'paragraphStyle'
			],
			[
				'emoticons',
				'insertLink',
				'insertImage',
				'insertTable',
				'insertAnchorBtn',
				'insertContainer'
			]
		]

	}

	async dispose()
	{
		this.editor().destroy();
		this.editor('');
	}


}

export default {
	name: 'email-html-editor',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
