const Message = Grape.dataModel.get('message-manager.message');
class EmailMessage extends Message {
	constructor(obj){
		super(obj)
	}

	createReply(){
		return this.get_message_obj('reply');
	}

	createForward(){
		return this.get_message_obj('forward');
	}

	async get_message_obj (type)
	{
		let ret = {
			status: 'New',
			channel: this.channel().name,
			driver: this.channel().driver,
			conversation_uuid: this.conversation_uuid(),
			property: {},
			"technical-info": {},
			content:[]
		};

		let htmlContentBody = this.contentList().find((c)=>c.name()=='htmlBody')?.body;

		// if there is not htmlBody content add one
		if (!htmlContentBody) {
			const htmlContent = await this.addContent({
				name:'htmlBody', role:'body', property:{ type: 'text', subtype: 'html' }
			});
			htmlContentBody = htmlContent.body;
			// if body property is not null then use it in html body and clear the property
			if (this.getPropertyValue('body')){
				htmlContentBody(this.getPropertyValue('body'));
				this.setPropertyValue('body', '');
			}
		}

		// copy content;
		for (const content of this.contentList()){
			ret.content.push(content.toJS());
		}

		if (type === 'reply')
		{
			ret.property = { subject: `Re: ${this.property.subject}` };
			ret["address-full"] = [
				{type:"email", role:"from", name: this.addressList().find((a)=>a.role()=='to')?.name()||''},
				{type:"email", role:"to", name: this.addressList().find((a)=>a.role()=='from')?.name()||''}
			];

			const htmlBody = ret.content.find((c)=>c.name=='htmlBody');
			htmlBody.body = `<p></p><div>On ${this.getPropertyValue('envelopedate')||'some date'}, ${this.addressList().find((a)=>a.role()=='from').name().replace(/<|>/g, '')} wrote:</div><blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">${htmlBody.body||''}</blockquote>`;

			ret.property.inreplyto = this.getPropertyValue('uniquereference')||this.message_uuid();
		}

		if (type === 'forward')
		{
			ret.property = { subject: `Fw: ${this.getPropertyValue('subject')}` };
			ret["address-full"] = [
				{type:"email", role:"from", name: this.channel().default_from_address},
				{type:"email", role:"to", name:''}
			];
		}
		return ret;
	}
}

export default EmailMessage;
