
import template from './email-message-view.html';

let Message;
/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		console.log(params);
		Message = Grape.dataModel.get('message-manager.message');
		this.editor = null;

		this.params = params;
		this.element = element;
		this.current_page_id = ko.observable('html');
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.isCollapsed = ko_helper.safe_observable(params.isCollapsed || true);

		this.showAllAddresses = ko.observable(false);
		this.isSpecialAddress = (role)=>{
			if (['sender', 'replyTo'].includes(role))
				return true;

			return false;
		};

		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true'); // hide or show addresses input

		this.init();
	}

	async init()
	{
		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (this.message() && (!this.message()?.addressList() || this.message()?.addressList()?.length==0))
			this.message().set({'address-full':[{ role: "from", type: "email", name: Grape.currentSession.user.email || this.message().channel()?.default_from_address || ''}] });

		//
		const editor_element = this.element.querySelector('.froala-editor');
		await new Promise((resolve) => {
			this.editor = new FroalaEditor(editor_element, {
				key: Grape.froala.key,
				attribution: false, // remove "Powered by" watermark
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		await this.set_message();

		// TODO remove
		this.message().getPropertyObservable('subject').subscribe((data) => {
			let str = data;
			if(str && data.length > 30)
				str = data.slice(0, 30)+'...'
			this.message().setPropertyValue('preview', str || '');
		});

		this.message.subscribe(() => this.set_message());
	}

	/* called when message changes */
	async set_message()
	{
		if (this.message())
		{
			await this.setMessageContent();

			if (this.editor.edit !== undefined)
			{
				this.editor.edit.off();
				this.editor.toolbar.hide();
			}

			let str = this.message().getPropertyValue('subject');
			if(str && str.length > 30)
				str = str.slice(0, 30)+'...'

			this.message().setPropertyValue('preview', str);
		}
	}

	async setMessageContent ()
	{
		const body = this.message().propertyList().find(prop => prop.name === 'body');

		if (body?.name == 'body' && this.message().contentList().length <= 0)
		{
			let newHtmlContent = await this.message().addContent('htmlBody', 'body', { type: 'text', subtype: 'html' })

			newHtmlContent.setBody(`<p>${body.value()}</p>`);
			await newHtmlContent.saveBody();
			this.editor.html.set(`<p>${body.value()}</p>`);
		}

		for (let content of this.message().contentList())
		{
			if (content.name() == 'textBody')
			{
				if (content.property.filename)
					await content.fetchBody();
				else if (content.property.body)
					await content.setBody(content.property);
			}
			else if (content.name() == 'htmlBody')
			{
				let body = await content.fetchBody();

				const html = this.updateInlineContent(body);
				this.editor.html.set(html);
			}
		}
	}

	updateInlineContent (html)
	{
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		const imgTags = tempDiv.getElementsByTagName('img');

		for (let img of imgTags)
		{
			if (img.hasAttribute('data-content-name'))
			{
				let name = img.getAttribute('data-content-name');
				img.src = `/api/messages/message/content?message_uuid=${this.message().message_uuid()}\&name=${name}`;
			}
			else if (img.getAttribute('src').startsWith('cid'))
			{
				const src = img.getAttribute('src');
				const cid = src.slice(4);

				img.src = `/api/messages/message/content?message_uuid=${this.message().message_uuid()}\&cid=${cid}`;
			}
			else if (img.hasAttribute('data-content-id'))
			{
				let content_id = img.getAttribute('data-content-id');
				img.src = `/api/messages/message/content?message_uuid=${this.message().message_uuid()}\&cid=${content_id}`;
			}
		}

		const updatedHtmlString = tempDiv.innerHTML;

		return updatedHtmlString;
	}

	formatHtml (html)
	{
		const updatedHtmlString = this.updateInlineContent(html);

		return updatedHtmlString.toString();
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}
}

export default {
	name: 'email-message-view',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
