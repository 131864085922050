// COMPONENTS
import EmailMsgEdit from './email-message-edit/email-message-edit.js';
import EmailMsgView from './email-message-view/email-message-view.js';
import EmailMsgTemplateEdit from './email-message-template-edit/email-message-template-edit.js';
import EmailHtmlEditor from './email-html-editor/email-html-editor.js';

export default [
	EmailHtmlEditor,
	EmailMsgEdit,
	EmailMsgView,
	EmailMsgTemplateEdit
];
