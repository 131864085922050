import template from './email-message-edit.html';

let Message;
let Template;
/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		this.params = params;
		this.element = element;
		Message = Grape.dataModel.get('message-manager.message');
		this.isEditable = ko_helper.safe_observable(params.isEditable || false);
		this.isCollapsed = ko_helper.safe_observable(params.isCollapsed);
		this.isTemplate = ko_helper.safe_observable(params.isTemplate || false);
		this.selected_template = ko.observable();
		this.callback = ko_helper.safe_observable(params.callback);
		this.message_input = ko_helper.safe_observable(params.message);
		this.folder_name = ko_helper.safe_observable(params.folder_name);

		this.editor = ko.observable();
		this.editor.subscribe(()=>{
			if (!this.editor())
				return;

			this.bindEditorEvents();
			this.setHtml(this.message().contentList().find((c)=>c.name()=='htmlBody')?.body()||'');
			this.isEditable.valueHasMutated();

			//TODO: this still adds to the top even after selecting a new cursor position
			this.editor().selection.setAtStart(this.editor().$el[0]);
		});

		this.tabs = ko.observableArray([
			{
				title: 'TEXT',
				page_id: 'text',
				icon: 'fa-solid fa-file-lines'
			},
			{
				title: 'HTML',
				page_id: 'html',
				icon: 'fa-solid fa-file-code'
			},
			{
				title: 'Attachments',
				page_id: 'attachments',
				icon: 'fa-solid fa-upload'
			},
			{
				title: 'Properties',
				page_id: 'advanced',
				icon: 'fa-solid fa-arrow-progress'
			}
		]);
		this.current_page_id = ko.observable('html');

		this.message = ko.observable();
		this.htmlContentBody;
		this.templates = ko.observableArray([]);
		this.selected_template.subscribe((t) => {
			if (typeof t != 'undefined')
				this.getTemplateData(t);
		});

		this.showAllAddresses = ko.observable(false);
		this.isSpecialAddress = (role)=>{
			if (['sender', 'replyTo'].includes(role))
				return true;

			return false;
		};

		this.init();
	}

	async init()
	{
		// create message instance if not already given one
		let msg;
		if (!(this.message_input() instanceof Message))
		{
			msg = new Message();
			await msg.set(this.message_input());
		} else {
			msg = this.message_input();
		}

		await msg.set({address_type: 'email'});

		this.htmlContentBody = msg.contentList().find((c)=>c.name()=='htmlBody')?.body;

		// if there is not htmlBody content add one
		if (!this.htmlContentBody) {
			let htmlContent = await msg.addContent({
				name:'htmlBody', role:'body', property:{ type: 'text', subtype: 'html' }
			});
			this.htmlContentBody = htmlContent.body;
			// if body property is not null then use it in html body and clear the property
			if (msg.getPropertyValue('body')) {
				this.htmlContentBody(msg.getPropertyValue('body'));
				msg.setPropertyValue('body', '');
			}
		}

		//make sure preview is set
		msg.setPropertyValue('preview', (this.htmlContentBody()||'').replace(/<[^>]*>/g, '').replace(/(.{0,200}).*/s, '$1...'));
		this.message(msg);

		// not isEditable by default but set depending on status
		if (['New', 'SendError'].includes(this.message().status())) {
			this.isEditable(true);
		} else {
			this.isEditable(false);
		}

		await this.getTemplates();
	}

	bindEditorEvents() {
		this.editor().events.on('image.beforeUpload', (images, response) => {
			const image = images[0];
			let types =  image.type.split('/');
			const cid = Date.now().toString(36);

			this.message().addContent({
				name:image.name,
				role:'inline',
				body:image,
				property: {
					name: image.name,
					type: types[0],
					subtype: types[1],
					content_id: cid
				}
			});
			const blobURL = URL.createObjectURL(image);
			this.editor().html.insert(`<img src="${blobURL}" data-cid="${cid}"></img>`);
			return false;
		});

		this.editor().events.on('image.removed', (element)=>{
			this.message().deleteContent(element[0].attributes['data-cid'].value, 'cid');
		});

		this.editor().events.on('contentChanged', ()=>{
			const html = this.froalaToEmailHTML(this.editor().html.get());
			const text = html.replace(/<[^>]*>/g, '');
			this.message().setPropertyValue('preview', text.replace(/(.{30}).*/, '$1'));
			this.message().contentList().find((c)=>c.name()=='textBody')?.body(text);
		});

		this.editor().events.trigger('contentChanged');
	}

	setHtml(html){
		this.editor()?.html.set(this.emailToFroalaHTML(html));
	};

	getHtml(){
		return this.froalaToEmailHTML(this.editor().html.get());
	}

	emailToFroalaHTML(html){
		if (!html)
			return '';

		const newHtml = html.replaceAll(/src="cid:([^"]*)"/g, (match, cid)=>{
			const blob = this.message().contentList().find((c)=>c.getPropertyValue("content_id")===decodeURIComponent(cid))?.body();
			let blobURL = `cid-${cid}-NotFound`;
			if (blob) {
				blobURL = URL.createObjectURL(blob);
			}
			return `src="${blobURL}" data-cid="${cid}"`;
		});
		return newHtml;
	}

	// on load
	froalaToEmailHTML(html){
		return html.replaceAll(/src="[^"]*" data-cid="([^"]*)"/g, (match, cid)=>{
			return `src="cid:${decodeURIComponent(cid)}"`
		});
	}

	async getTemplates ()
	{
		//TODO replace with cache
		try
		{
			const result = await Grape.fetches.getJSON(`/api/record`, {
				schema: 'messages',
				table: 'v_templates',
				filter: [
					{
						field: 'driver_name',
						operand: '=',
						value: 'Email'
					}
				]
			});

			if (result.status == 'OK')
				this.templates(result.records || []);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}
	}

	async getTemplateData (t)
	{
		/**
		 * TODO: 2) Mustache
		*/

		let templateMessage = new Message({message_uuid: t.message_uuid});
		await templateMessage.fetch(['property', 'content.property']);
		await this.message().setPropertyValue('subject', templateMessage.getPropertyValue('subject'));

		let htmlBody = templateMessage.contentList().find((c) => c.name() === 'htmlBody');
		let body = templateMessage.getPropertyValue('body');
		if (htmlBody)
		{
			let images = templateMessage.contentList().filter((c) => c.type() === 'image');
			for (const image of images) {
				await this.message().addContent({
					name:image.name(),
					role:image.role(),
					body:image.body(),
					property: {
						name: image.name(),
						type: image.type(),
						subtype: image.subtype(),
						content_id: image.cid()
					}
				});
			}

			await this.editor().html.insert(this.emailToFroalaHTML(htmlBody.body()));
		}
		else if (body)
		{
			this.message().contentList().find((c)=>c.name()=='textBody')?.body(body);
			await this.editor().html.insert(this.emailToFroalaHTML(body));
		}
	}

	buildTemplateProperty (value)
	{
		let property = {};
		for (var i = 0; i < value.length; i++)
		{
			property[value[i].name] = value[i].value();
		}
		return property;
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}

	async onContentFileUpload(event)
	{
		await this.message().uploadContent(event.target.files);
		await this.message().fetch(['content.property']);
	}

	async btnSend ()
	{
		if (!(await Grape.alerts.confirm({ type: 'warning', title: 'Confirm',  message: 'Are you sure you want to send this message?' })))
			return;

		await this.message().setPropertyValue('envelopedate', new Date().toISOString());

		if(typeof this.message().message_uuid() === 'undefined')
			await this.message().create({folder_name: this.folder_name()});

		const htmlBodyContent = this.message().contentList().find((c)=>{return c.name()==='htmlBody'});
		htmlBodyContent.body(this.getHtml());
		await this.message().save();

		await this.message().send();
		this.callback(Date.now());
	}

	async btnSave ()
	{
		if(typeof this.message().message_uuid() === 'undefined')
			await this.message().create({folder_name: this.folder_name()});

		const htmlBodyContent = this.message().contentList().find((c)=>{return c.name()==='htmlBody'});
		htmlBodyContent.body(this.getHtml());
		await this.message().save();

		this.callback(Date.now());
	}

	deleteContent (content)
	{
		let updatedContentList = this.message().contentList().filter(x => x.name() !== content.name());
		this.message().contentList(updatedContentList);
	}
}

export default {
	name: 'email-message-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
