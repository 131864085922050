
import components from './components/index.js';
// import dialogs from './dialogs/index.js';
//import pages from './pages/index.js';
//import appcaches from './caches/index.js';
//import plugins from './plugins/index.js';
import dataModels from './models/index.js';

import package_json from '../package.json';

(function() {

	window.Grape.modules.push(package_json);

	//window.Grape.cache.registerCaches(appcaches);
	window.Grape.dataModel.registerDataModels(dataModels);
	window.Grape.component.registerComponents(components);
	// window.Grape.dialog.registerDialogs(dialogs);
	//window.Grape.pages.registerPages(pages);
	//window.Grape.plugins.registerPlugins(plugins);

})();
