import template from './email-message-template-edit.html';

let Message;
/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		Message = Grape.dataModel.get('message-manager.message');
		this.isEditable = ko_helper.safe_observable(params.isEditable || false);
		this.isCollapsed = ko_helper.safe_observable(params.isCollapsed);
		this.callback = ko_helper.safe_observable(params.callback);
		this.isTemplate = ko.observable(true);

		this.editor = ko.observable();
		this.message = ko_helper.safe_observable(params.message);
	}
}

export default {
	name: 'email-message-template-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
